import { template as template_d6a96c74f76f41d2a0616c6af5642f57 } from "@ember/template-compiler";
const FKLabel = template_d6a96c74f76f41d2a0616c6af5642f57(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
