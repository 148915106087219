import { template as template_0b0857607d6a44b3a36a435df81cbeb2 } from "@ember/template-compiler";
const FKControlMenuContainer = template_0b0857607d6a44b3a36a435df81cbeb2(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
