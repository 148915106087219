import { template as template_78ae6368958e498e9c3712099d72980c } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_78ae6368958e498e9c3712099d72980c(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
