import { template as template_76160b04abf644188e9d5ddd0e4d8e60 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_76160b04abf644188e9d5ddd0e4d8e60(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
